import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Text } from '@mantine/core';
import ResponsiveModal from './ResponsiveModal';
import FormSection from './FormSection';

const ConfirmCheckbox = ({
  confirmTitle,
  confirmMessage,
  confirmDisabled,
  variant,
  onChange,
  checked,
  showConfirmModal,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onCheckboxChange = () => {
    if (showConfirmModal) {
      setModalOpen(true);
    }
    else {
      onChange(!checked);
    }
  };

  return (
    <>
      {variant === 'switch' ? (
        <Switch
          checked={checked}
          description="Restrict vendor visibility to SportsHeadz staff only; the demo vendor is intended for demonstration purposes and should not be used for real transactions."
          onChange={onCheckboxChange}
          {...props}
        />
      ) : (
        <Switch
          checked={checked}
          description="Restrict vendor visibility to SportsHeadz staff only; the demo vendor is intended for demonstration purposes and should not be used for real transactions."
          onChange={onCheckboxChange}
          {...props}
        />
      )}

      <ResponsiveModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={confirmTitle}
      >
        <FormSection
          cancelTitle="Cancel"
          isSubmitHidden={confirmDisabled}
          onCancel={() => setModalOpen(false)}
          onSubmit={(e) => {
            e.stopPropagation();
            setModalOpen(false);
            onChange(!checked);
          }}
          submitColor="blue"
          submitTitle="Confirm"
        >
          {typeof confirmMessage === 'string' ? (
            <Text style={{ fontSize: 14, color: '#666' }}>
              {confirmMessage}
            </Text>
          ) : (
            confirmMessage
          )}
        </FormSection>
      </ResponsiveModal>
    </>
  );
};

ConfirmCheckbox.propTypes = {
  checked: PropTypes.bool,
  confirmMessage: PropTypes.any,
  confirmTitle: PropTypes.string,
  onChange: PropTypes.func,
  props: PropTypes.object,
  showConfirmModal: PropTypes.bool,
  variant: PropTypes.string,
  confirmDisabled: PropTypes.bool
};

export default ConfirmCheckbox;
